import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import RecommendedPosts from "../components/RecommendedPosts"

import * as S from "../components/Post/styled"

const BlogPost = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const next = pageContext.nextPost
  const previous = pageContext.previousPost

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <S.PostHeader>
        <S.PostDate>
          {post.frontmatter.date} • {post.timeToRead} min de leitura
        </S.PostDate>
        <S.PostTitle>{post.frontmatter.title}</S.PostTitle>
        <S.PostDescription>{post.frontmatter.description}</S.PostDescription>
        <S.AuthorWrapper>
          <S.AuthorAvatar fluid={post.frontmatter.author.avatarUrl.childImageSharp.fluid} />
          <S.PostAuthor>Publicado por {post.frontmatter.author.id}</S.PostAuthor>
        </S.AuthorWrapper>
      </S.PostHeader>
      <S.MainContent>
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
      </S.MainContent>
      <RecommendedPosts next={next} previous={previous} />
    </Layout>
  )
}

export const query = graphql`
  query Post($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
        author {
          id
          avatarUrl {
            childImageSharp {
              fluid(maxWidth: 150) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        category {
          id
        }
      }
      html
      timeToRead
    }
  }
`

export default BlogPost

//TODO: ARRUMAR O CSS DESSAS COISAS AI