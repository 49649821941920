import styled from "styled-components"
import media from "styled-media-query"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const RecommendedWrapper = styled.section`
  background: var(--mediumBackground);
  margin: auto;
  max-width: 65vh;
  display: block;
`

export const RecommendedLink = styled(AniLink)`
  position: relative;
  align-items: center;
  background: var(--mediumBackground);
  color: var(--highlight);
  border: 2px solid var(--borders);
  margin: 30px 0 30px 0;
  display: block;
  padding: 3rem;
  text-decoration: none;
  transition: background 0.5s;

  ${media.lessThan("large")`
    padding: 2rem 1rem;
    line-height: 1.3;
    font-size: .9rem;
  `}

  &:hover {
    background: var(--borders);
  }

  &.previous {
    border-right: 1px solid var(--borders);
  }

  &.next {
    justify-content: flex-end;
  }

  &.previous:before {
    content: "\\2190";
    margin-right: 0.5rem;
  }

  &.next:after {
    content: "\\2192";
    margin-left: 0.5rem;
  }
`
export const RecommendedDescription = styled.p`
font-size: 1rem;
font-weight: 300;
line-height: 1.4;

${media.lessThan("large")`
  display: none;
`}`