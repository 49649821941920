import React from "react"
import propTypes from "prop-types"

import * as S from "./styled"

const RecommendedPosts = ({ next, previous }) => (
  <S.RecommendedWrapper>
    {next && (
      <S.RecommendedLink
        fade
        to={next.fields.slug}
      >
        {next.frontmatter.title}.
        <S.RecommendedDescription>
          {next.frontmatter.description}
        </S.RecommendedDescription>
      </S.RecommendedLink>
    )}
    {previous && (
      <S.RecommendedLink
        fade
        to={previous.fields.slug}
      >
        {previous.frontmatter.title}.
        <S.RecommendedDescription>
          {previous.frontmatter.description}
        </S.RecommendedDescription>
      </S.RecommendedLink>
    )}
  </S.RecommendedWrapper>
)

RecommendedPosts.propTypes = {
  next: propTypes.shape({
    frontmatter: propTypes.shape({
      title: propTypes.string.isRequired,
      description: propTypes.string.isRequired,
    }),
    fields: propTypes.shape({
      slug: propTypes.string.isRequired,
    }),
  }),
  previous: propTypes.shape({
    frontmatter: propTypes.shape({
      title: propTypes.string.isRequired,
      description: propTypes.string.isRequired,
    }),
    fields: propTypes.shape({
      slug: propTypes.string.isRequired,
    }),
  }),
}

export default RecommendedPosts
